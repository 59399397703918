import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Lexend\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-lexend\"}],\"variableName\":\"lexend\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ServiceWorkerRegister.jsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/tailwind.css");
